import React, { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const App: React.FC = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      // console.log("test");

      window.location.href = "https://www.instagram.com/hedgearchitects/";
    }, 500);

    return () => clearTimeout(timer); // Cleanup if the component unmounts
  }, []);

  return (
    <div className="App" style={{ textAlign: "center", marginTop: "20%" }}>
      <h1>Redirecting to Instagram...</h1>
      <ClipLoader color="#3498db" loading={true} size={50} />
    </div>
  );
};

export default App;
